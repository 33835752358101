/*!
 * mLeasing, footer portlet :: 07/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import { environment } from '../../../environment.js';
import { Box } from '@mbank-design/design-system/components';
import { Text } from '@mbank-design/design-system/components';
import { Image } from '@mbank-design/design-system/components';
import { Spacing } from '@mbank-design/design-system/enums';
import { px } from '@mbank-design/design-system/utils';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import Container from '../../components/container.js';
import ScrollTopButton from '../../components/scroll-top-button.js';
import { mapLinks } from '../../utils/map-links.js';
import LinkGroup from './link-group';
import { generateLink } from '../../utils/generate-link.js';
import { calculateSiteHeight } from '../../utils/calculate-site-height.js';

const version = 'v: 1.0.1 :: r. 11/05/2021 @ fs';
const FooterList = styled.ul`
	display: flex;
	width: 100%;
	list-style: none;
	justify-content: space-between;
	padding-top: ${px(Spacing.SPACE_56)};
	padding-bottom: ${px(Spacing.SPACE_56)};
	padding-left: ${px(Spacing.SPACE_0)};
	margin-top: ${px(Spacing.SPACE_0)};
	margin-bottom: ${px(Spacing.SPACE_0)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		padding-top: ${px(Spacing.SPACE_40)};
		padding-bottom: ${px(Spacing.SPACE_40)};
	}
`;
const BottomContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: ${px(Spacing.SPACE_40)};
	padding-bottom: ${px(Spacing.SPACE_40)};
	max-width: ${px(1315)};
	margin-top: ${Spacing.SPACE_0};
	margin-right: auto;
	margin-bottom: ${Spacing.SPACE_0};
	margin-left: auto;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const SocialMediaLinks = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		order: 0;
		margin-bottom: ${px(Spacing.SPACE_16)};
	}
`;
const OrderedBox = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		order: 1;
	}
`;
const qrqsLinkStyles = `
	color: ${palette.zeusBlackUI};
	font-weight: normal;
	padding-left: 4px;
	&:hover {
		text-decoration: none;
	}
`;

const mapGroups = (groups, offline) => {
	return groups && groups.length ? groups.map(group => {

		let links = [];

		if (offline) {
			links = group.links.map(link => {
				return {
					linkName: link.linkName,
					link: mapLinks([{
						url: link.link,
						navText: link.linkName,
					}], offline)[0].url,
					openInNewTab: link.openInNewTab,
					icon: link.icon
				};
			});
		} else {
			links = group.Link.map(link => {
				return {
					linkName: link.LinkName,
					link: mapLinks([{
						url: link.Link.link.target,
						navText: link.LinkName,
					}], offline)[0].url,
					openInNewTab: link.OpenInNewTab,
					icon: link.LinkIcon
				};
			});
		}

		return {
			groupTitle: offline ? group.groupTitle : group.GroupTitle,
			expandable: offline ? group.expandable : group.Expandable,
			open: false,
			links: links
		};
	}) : [];
};
const buildState = (props) => {

	let obj = {};

	if (!props.offline) {

		try {
			obj = props.data['FooterData']['Footer'];
		} catch (err) {
			console.log(err);
		}
	}

	return {
		offline: props.offline,
		pageType: props.pageType,
		showScrollTopButton: false,
		groups: props.data ? mapGroups((!props.offline ? obj.Group : props.data.groups), props.offline) : []
	};

};

function consentChanged() {

	if (typeof mbiscuit === 'object') {
		window['mbiscuit'].open();
	}

};

class Footer extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.consentChanged = this.consentChanged.bind(this);


		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}
	consentChanged() {
		return consentChanged;
	}



	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('footer');
		}

		let timeout = setTimeout(() => {

			const height = calculateSiteHeight();

			if (height >= environment.siteHeight) {
				this.setState({
					offline: this.state.offline,
					pageType: this.state.pageType,
					showScrollTopButton: true,
					groups: this.state.groups
				});
			}
			clearTimeout(timeout);
		}, 1000);
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props);
	}

	render() {
		return (
			<>
				{this.state.groups && this.state.groups.length ? <Box as="div" className="outer">
					<Container>
						<Box as="nav" className="nav">
							<FooterList>
								{this.state.groups.map((group, i) => {
									return (<LinkGroup
										key={'group' + i}
										data={group}
										offline={this.state.offline}
										itemIndex={i}
										prefix={this.props.prefix}
									/>);
								})}
							</FooterList>
						</Box>
					</Container>
				</Box> : ''}
				{this.state.showScrollTopButton ? <ScrollTopButton /> : ''}
				<Box as="div" className="outer" background={palette.wildSandGray}>
					<BottomContainer>
						<OrderedBox>
							<Text as="small" color="doveGrayUI">

								{generateLink('https://qrqs.eu/', '@2021 mLeasing sp. z o.o. / Powered by', qrqsLinkStyles, '', this.state.offline)}
								{generateLink('https://qrqs.eu/', '', qrqsLinkStyles, (
									<Image
										src={(environment.type == 'blog' ? "/blog" : '') + "/.templates/img/icons/qrqs_logo.svg"}
										alt="Quercus – Software on demand, on time"
										title="Quercus – Software on demand, on time"

									/>
								), this.state.offline)}</Text>
						</OrderedBox>
						<SocialMediaLinks>
							<Box as="div" marginRight={px(Spacing.SPACE_24)}>
								{generateLink('https://www.facebook.com/mLeasingPolska/', '', '', (
									<Image
										src={(environment.type == 'blog' ? "/blog" : '') + "/.templates/img/icons/facebook.png"}
										alt="facebook"
										title="facebook"
										width={Spacing.SPACE_24}
										height={Spacing.SPACE_24}
									/>
								), this.state.offline)}
							</Box>
							<Box as="div" marginRight={px(Spacing.SPACE_24)}>
								{generateLink('https://www.linkedin.com/company/bre-leasing-sp.-z-o.o./', '', '', (
									<Image
										src={(environment.type == 'blog' ? "/blog" : '') + "/.templates/img/icons/linkedin.png"}
										alt="linkedin"
										title="linkedin"
										width={Spacing.SPACE_24}
										height={Spacing.SPACE_24}
									/>
								), this.state.offline)}
							</Box>
							<Box as="div">
								{generateLink('https://www.instagram.com/mleasing.pl/', '', '', (
									<Image
										src={(environment.type == 'blog' ? "/blog" : '') + "/.templates/img/icons/instagram.png"}
										alt="instagram"
										title="instagram"
										width={Spacing.SPACE_24}
										height={Spacing.SPACE_24}
									/>
								), this.state.offline)}
							</Box>
						</SocialMediaLinks>
					</BottomContainer>
				</Box>
			</>
		);
	}

}

export default Footer;
