/*!
 * mLeasing, news portlet :: 10/06/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Select} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Image} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {Icon} from '@mbank-design/design-system/components';
import {ChevronRight} from '@mbank-design/design-system/icons';
import {ChevronLeft} from '@mbank-design/design-system/icons';
import {ChevronUp} from '@mbank-design/design-system/icons';
import {ChevronDown} from '@mbank-design/design-system/icons';
import {Filter} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import {ButtonColor} from '@mbank-design/design-system/enums';
import {HorizontalLine} from '@mbank-design/design-system/components';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {mapLinks} from '../../utils/map-links.js';
import Container from '../../components/container.js';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links.js';
import {componentsFromHtml} from '../../utils/components-from-html.js';
import {generateLink} from '../../utils/generate-link.js';

const version = 'v: 0.2.2 :: r. 15/11/2021 @ vlk';
const ListContainer = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const ListBox = styled.div`
	flex-basis: 66.66%;
	flex-grow: 1;
	flex-shrink: 1;
`;
const FilterContainer = styled.div`
	flex-basis: 333px;
	flex-grow: 1;
	flex-shrink: 0;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: ${props => props.open ? 'block' : 'none'};
		position: absolute;
		top: 92px;
		background-color: ${palette.wildSandGray};
		width: 100%;
		z-index: 1;
		padding-top: 16px;
	}
`;
const FilterBox = styled(Box)`
	background-color: ${palette.solidWhite};
	margin-bottom: 24px;
	margin-left: 24px;
	padding: 24px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-left: 0;
		margin-bottom: 16px;
		padding: 16px;
	}
`;
const NewsItem = styled(Box)`
	display: flex;
	height: ${props => props['data-index'] === 0 ? '368px' : '216px'};
	background-color: ${palette.solidWhite};
	box-shadow: ${px(Spacing.SPACE_4)} ${px(Spacing.SPACE_4)} 0 0 rgba(0, 0, 0, 0.08);
	margin-bottom: 24px;
	position: relative;
	overflow: hidden;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		height: auto;
		margin-bottom: 16px;
	}
`;
const ImageContainer = styled.div`
	max-width: ${props => props['data-index'] === 0 ? '100%' : '309px'};
	width: 100%;
	position: ${props => props['data-index'] === 0 ? 'absolute' : 'relative'};
	height: 100%;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}

	&::after {
		content: "";
		display: block;
		position: absolute;
		inset: 0px;
		background: ${props => props['data-index'] === 0? 'rgba(0, 0, 0, 0) linear-gradient(to left, transparent, rgb(0, 0, 1) 90%, rgb(0, 0, 0)) repeat scroll 0% 0%' : 'none'};
	}

	& img {
		display: block;
		max-width: ${props => props['data-index'] === 0 ? '100%' : '309px'};
		min-width: 100%;
		min-height: 100%;
	}
`;
const MobileImageContainer = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: none;

	& img {
		display: block;
	}

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: ${props => props['data-index'] === 0 ? 'flex-end' : 'flex-start'};
	padding: ${props => props['data-index'] === 0 ? '40px' : '32px'};
	position: relative;
	z-index: 1;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		position: relative;
		padding: 24px 16px;
	}
`;
const Inner = styled.div`
	max-width: 604px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: none;
	}
`;
const MobileInner = styled.div`
	max-width: 100%;
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
	}
`;
const TitleRow = styled.div`
	display: flex;
	align-items: center;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 16px;
	}
`;
const MarkContainer = styled.div`
	display: flex;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-bottom: 16px;
	}
`;
const FilterMark = styled.div`
	color: ${palette.solidWhite};
	background-color: ${palette.endeavourBlueUI};
	border-radius: 3px;
	padding-left: 4px;
	padding-right: 4px;
	margin-right: 8px;
	line-height: 1rem;
`;
const PopularMark = styled(FilterMark)`
	background-color: ${palette.flushOrange};
`;
const CategoryMark = styled(FilterMark)`
	background-color: ${palette.endeavourBlueUI};
`;
const Separator = styled.div`
	height: ${px(Spacing.SPACE_16)};
`;
const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
`;
const PaginationItem = styled.div`
	margin: 0 8px;
	cursor: pointer;
	padding: 12px;
	width: 40px;
	text-align: center;
	color: ${props => props.active ? palette.solidWhite : palette.endeavourBlueUI};
	background-color: ${props => props.active ? palette.endeavourBlueUI : props.background};
`;
const PaginationIconContainer = styled.div`
	display: ${props => props.elementDisplay ? 'flex' : 'none'};
	align-items: center;
	margin-right: ${props => props.marginRight || 0};
	margin-left: ${props => props.marginLeft || 0};
	cursor: pointer;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: 0;
		margin-left: 0;
	}
`;
const FiltersNav = styled.div`
	display: none;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		display: block;
		cursor: pointer;
		position: fixed;
		top: 36px;
		right: 60px;
		z-index: 2;
	}
`;
const linkStyle = `
	display: block;
	margin-top: 15px;
	margin-bottom: 16px;
	color: ${palette.zeusBlackUI};
`;
const buildState = (props, state) => {

	let	title = '';

	if (props.offline) {
		title = props.data.title;
	} else {

		try {

			let obj = props.data['NewsData']['News'];

			title = obj.Title;
		} catch (err) {
			console.log(err);
		}
	}
	return  {
		offline: props.offline,
		pageType: props.pageType,
		title: title,
		items: state ? state.items : [],
		popular: state ? state.popular : [],
		categories: state ? state.categories : [],
		pageNumber: state ? state.pageNumber : 1,
		openFilters: state ? state.openFilters : false,
		openCategories: state ? state.openCategories : false,
		category: state ? state.category : '',
		archiveData: state ? state.archiveData : {
			data: [],
			selectItems: [],
			selectValue: ''
		}
	};

};

class News extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderNewsList = this.renderNewsList.bind(this);
		this.renderNewsItem = this.renderNewsItem.bind(this);
		this.renderPopular = this.renderPopular.bind(this);
		this.renderFilters = this.renderFilters.bind(this);
		this.renderArchive = this.renderArchive.bind(this);
		this.updateState = this.updateState.bind(this);
		this.filterByDate = this.filterByDate.bind(this);
		this.filterByCategory = this.filterByCategory.bind(this);
		this.renderPagination = this.renderPagination.bind(this);
		this.setPage = this.setPage.bind(this);
		this.openFilters = this.openFilters.bind(this);
		this.toggleCategories = this.toggleCategories.bind(this);

		this.state = buildState(props);
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('news');
		}

		// const url = 'https://test-mleasing11.qrqs.eu/api/paths/data.json'
		const url = (this.state.offline ? environment.offlineApiPathsPath : environment.onlineApiPathsPath) + environment.apiPathsFileName;

		fetch(url)
			.then(res => res.json())
			.then(json => {
				let paths = json.filter(path => path.match(/^\/aktualnosci/) && ! path.match(/index.html/) && !path.match(/^\/aktualnosci\/$/));
				let items = [];
				let rootType = '';
				Promise.all(paths.map(path => {
					if (environment.type == 'blog' && path.match(/\/blog-mauto\//)) {
						rootType = '/blog'
					}

					let url = environment.onlineApiPath.slice(0, -1) + rootType + path + 'index.html.json';
					if (this.state.offline) {
						// url = path + '?__json=true';
						url = path + 'index.html?__json=true';
					}
					return fetch(url)
						.then(res => res.json())
						.then(json => {
							let article;
							let categories = [];
							
							if (json['page'].hasOwnProperty('category')) { // get categories
								json['page'].category.forEach(obj => {
									for (let key in obj) {
										categories.push({
											name: key,
											title: obj[key]
										});
									}
								});
							}
							for (let key in json) {
								let pops = [];
								if (key.match(/article/)) {
									article = json[key];

									let title = '';
									let newsTeaser = '';
									let image = '';
									let mobileImage = '';
									let altText = '';
									let popular = false;
									let date = '';

									if (this.state.offline) {

										let obj = JSON.parse(article);
										let arr = [];

										if (obj.category && obj.category !== '[]') {
											arr = obj.category.replace(/\]/g, '').replace(/\[/g, '').split(',');
											arr = arr.map(cat => {
												
												let catArr = cat.split(':')[1].trim().replace('/sites/mleasing/.categories/', '').split('/');

												catArr = catArr.filter(c => c); // check for empty strings
												return catArr[catArr.length - 1];
											});
										}

										arr.forEach(cat => {
											
											if (!categories.includes(cat)) {
												categories.push({
													name: cat,
													title: cat
												});
											}

										});
										title = obj.title;
										newsTeaser = obj.newsTeaser;
										image = obj.image;
										mobileImage = obj.mobileImage;
										altText = obj.altText;
										popular = obj.isPopular;
										pops = obj.isPopular;
										date = obj.date;
									} else {

										let obj = article['ArticleData']['Article'];

										title = obj.Title;
										newsTeaser = obj.NewsTeaser;
										image = obj.Image ? obj.Image.link.target.replace(environment.cmsExportPath, '') : '';
										mobileImage = obj.MobileImage ? obj.MobileImage.link.target.replace(environment.cmsExportPath, '') : '';
										altText = obj.AltText;
										popular = obj.IsPopular;
										pops = obj.IsPopular;
										date = obj.Date;
									}
									items.push({
										title: title,
										newsTeaser: newsTeaser.slice(0, 130),
										path: path,
										image: image,
										mobileImage: mobileImage,
										altText: altText,
										popular: pops,
										date: date,
										categories: categories,
										visible: true
									});
								}

							}

						})
						.catch(err => console.log(err));
				})).then((res) => {

					// sort news by date
					const quickSort = (arr) => {
						
						const sort = (arr) => {
							
							let sorted = [arr[0]];
							let bigger = [];
							let lower = [];
							
							arr.forEach(el => {

								// js date needs reverted order 
								let elDateArr = el.date.split('/');
								let elDate = new Date(elDateArr[1] + '/' + elDateArr[0] + '/' + elDateArr[2]);
								let firstElDateArr = sorted[0].date.split('/');
								let firstElDate = new Date(firstElDateArr[1] + '/' + firstElDateArr[0] + '/' + firstElDateArr[2]);

								if (elDate > firstElDate) {
									bigger.push(el);
								}

								if (elDate < firstElDate) {
									lower.push(el);
								}
									
							});
							
							if (lower.length) {
								lower = sort(lower);
							}
							
							if (bigger.length) {
								bigger = sort(bigger);
							}
							
							sorted = lower.concat(sorted).concat(bigger);
							return sorted;
						}
						
						return sort(arr);
					}

					let sortedItems = quickSort(items).reverse();
					let popularItems = sortedItems.filter((item) => item.popular)
						.filter((item, i) => i < 3)
						.map(item => ({
							path: item.path,
							title: item.title
						}));


					// build archive navigation from data
					let archiveItems = sortedItems.filter((item, i) => !item.date.match(new Date().getFullYear()));
					let archiveYears = [];
					let archiveData = [];

					archiveItems.forEach(item => {
						
						let year = item.date.split('/')[2];

						if (year && !archiveYears.includes(year)) {
							archiveYears.push(year);
						}

					});
					archiveYears.forEach(year => {

						let months = archiveItems
							.filter(item => item.date.match(year))
							.map(item => item.date.split('/')[1])
						let monthsArr = [];

						months.forEach(item => {
						
							if (!monthsArr.includes(item)) {
								monthsArr.push(item);
							}

						});

						months = monthsArr.map(item => {

							let monthLabel = '';

							if (parseInt(item) === 1) {
								monthLabel = 'styczeń';
							}
							if (parseInt(item) === 2) {
								monthLabel = 'luty';
							}
							if (parseInt(item) === 3) {
								monthLabel = 'marzec';
							}
							if (parseInt(item) === 4) {
								monthLabel = 'kwiecień';
							}
							if (parseInt(item) === 5) {
								monthLabel = 'maj';
							}
							if (parseInt(item) === 6) {
								monthLabel = 'czerwiec';
							}
							if (parseInt(item) === 7) {
								monthLabel = 'lipiec';
							}
							if (parseInt(item) === 8) {
								monthLabel = 'sierpień';
							}
							if (parseInt(item) === 9) {
								monthLabel = 'wrzesień';
							}
							if (parseInt(item) === 10) {
								monthLabel = 'październik';
							}
							if (parseInt(item) === 11) {
								monthLabel = 'listopad';
							}
							if (parseInt(item) === 12) {
								monthLabel = 'grudzień';
							}
							return {
								month: item,
								monthLabel: monthLabel,
								active: false
							};
						});
						archiveData.push({
							year: year,
							months: months
						});
					});

					let selectItems = archiveData.filter((data, i) => i !== 0)
						.map((item, i) => ({
							value: item.year,
							label: item.year
						}));
					let selectValue = '';

					sortedItems = sortedItems.map((item, i) => ({ // paginate on first render
						title: item.title,
						date: item.date,
						image: item.image,
						mobileImage: item.mobileImage,
						altText: item.altText,
						path: item.path,
						popular: item.popular,
						categories: item.categories,
						newsTeaser: item.newsTeaser,
						visible: item.visible,
						inCurrentPage: i < 5 ? true : false
					}));

					let categories = [];

					sortedItems.forEach(item => {
						item.categories.forEach(category => {
							
							let arr = categories.filter(cat => cat.name === category.name);

							if (!arr.length) {
								categories.push(category);
							}

						});
					});
					this.setState({
						offline: this.state.offline,
						pageType: this.state.pageType,
						title: this.state.title,
						openFilters: this.state.openFilters,
						openCategories: this.state.openCategories,
						category: this.state.category,
						archiveData: {
							data: archiveData,
							selectItems: selectItems,
							selectValue: selectValue
						},
						items: sortedItems,
						popular: popularItems,
						categories: categories
					});
				});
			})
			.catch(err => console.log(err));
	}

	static getDerivedStateFromProps(props, state) {
		return buildState(props, state);
	}

	openFilters() {
		this.updateState('openFilters', true);
	}

	toggleCategories() {
		this.updateState('openCategories', !this.state.openCategories);
	}

	updateState(key, value) {
		this.setState({
			offline: this.state.offline,
			pageType: this.state.pageType,
			title: this.state.title,
			items: key === 'items' ? value : this.state.items,
			popular: this.state.popular,
			pageNumber: key === 'pageNumber' ? value : this.state.pageNumber,
			openFilters: key === 'openFilters' ? value : this.state.openFilters,
			openCategories: key === 'openCategories' ? value : this.state.openCategories,
			category: key === 'category' ? value : this.state.category,
			archiveData: {
				data: key === 'archiveData' ? value : this.state.archiveData.data,
				selectItems: this.state.archiveData.selectItems,
				selectValue: key === 'selectValue' ? value : this.state.archiveData.selectValue
			}
		});
	}

	filterByDate(year, month) {
		this.updateState('pageNumber', 1);

		let timeout1 = setTimeout(() => {
			this.updateState('openFilters', false);
			clearTimeout(timeout1);
		}, 0);
		let timeout2 = setTimeout(() => {
			this.updateState('category', '');
			clearTimeout(timeout2);
		}, 0);
		let timeout3 = setTimeout(() => {

			let archiveData = this.state.archiveData.data.map(obj => {
				return {
					year: obj.year,
					months: obj.months.map(monthObj => {
						return {
							month: monthObj.month,
							monthLabel: monthObj.monthLabel,
							active: parseInt(monthObj.month) && parseInt(monthObj.month) === parseInt(month.month)
						};
					})
				};
			});
			this.updateState('archiveData', archiveData);
			clearTimeout(timeout3);
		}, 0);
		let itemIndex = 0;
		let timeout4 = setTimeout(() => {
			this.updateState('items', this.state.items.map(item => {

				let dateArr = item.date.split('/');
				let visible = false;
				let inCurrentPage = false;

				if (dateArr[1] === month.month && dateArr[2] === year) {
					visible = true;
					inCurrentPage = itemIndex >= (this.state.pageNumber - 1) * 5 && itemIndex < this.state.pageNumber * 5;
					itemIndex++;
				}

				return {
					title: item.title,
					newsTeaser: item.newsTeaser,
					path: item.path,
					image: item.image,
					mobileImage: item.mobileImage,
					altText: item.altText,
					popular: item.popular,
					categories: item.categories,
					date: item.date,
					inCurrentPage: inCurrentPage,
					visible: visible
				};
			}));
			clearTimeout(timeout4);
		}, 0);
	}

	filterByCategory(category) {
		this.updateState('pageNumber', 1);

		let timeout1 = setTimeout(() => {
			this.updateState('openFilters', false);
			clearTimeout(timeout1);
		}, 0);
		let timeout2 = setTimeout(() => {
			this.updateState('category', this.state.category !== category.name ? category.name : '');
			clearTimeout(timeout2);
		}, 0);
		let itemIndex = 0;
		let timeout3 = setTimeout(() => {
			this.updateState('items', this.state.items.map((item, i) => {

				let visible = false;
				let inCurrentPage = false;

				if (this.state.category) {
					item.categories.forEach(cat => {
					
						if (cat.name === this.state.category) {
							visible = true;
							inCurrentPage = itemIndex >= (this.state.pageNumber - 1) * 5 && itemIndex < this.state.pageNumber * 5;
							itemIndex++;
						}

					});
				} else {
					visible = true;
					inCurrentPage = itemIndex >= (this.state.pageNumber - 1) * 5 && itemIndex < this.state.pageNumber * 5;
					itemIndex++;
				}

				return {
					title: item.title,
					newsTeaser: item.newsTeaser,
					path: item.path,
					image: item.image,
					mobileImage: item.mobileImage,
					altText: item.altText,
					popular: item.popular,
					categories: item.categories,
					date: item.date,
					inCurrentPage: inCurrentPage,
					visible: visible
				};
			}));
			clearTimeout(timeout3);
		}, 0);
	}

	renderPopular() {
		return (<FilterBox key={'filter-box-1'}>
			<Text as="h4">popularne artykuły</Text>
			<Separator />
			<HorizontalLine />
			<Separator />
			<Box>
				{this.state.popular.map((item, i) => {
					return (<Box key={'item-' + i}>{
						generateLink(this.props.prefix + item.path, item.title, linkStyle, '', this.state.offline)
					}</Box>);
				})}
			</Box>
		</FilterBox>);
	}

	setPage(pageNumber) {
		this.updateState('pageNumber', pageNumber);

		let itemIndex = 0;
		let timeout = setTimeout(() => {
			this.updateState('items', this.state.items.map((item, i) => {
				
				let inCurrentPage = false;

				if (item.visible) {
					inCurrentPage = itemIndex >= (this.state.pageNumber - 1) * 5 && itemIndex < this.state.pageNumber * 5;
					itemIndex++;
				}

				return {
					title: item.title,
					newsTeaser: item.newsTeaser,
					path: item.path,
					image: item.image,
					mobileImage: item.mobileImage,
					altText: item.altText,
					popular: item.popular,
					categories: item.categories,
					date: item.date,
					inCurrentPage: inCurrentPage,
					visible: item.visible
				};
			}));
			clearTimeout(timeout);
		}, 0);

	}

	renderPagination() {

		const pageNumber = this.state.pageNumber;
		const numberOfItems = this.state.items.filter(item => item.visible).length;
		const numberOfPages = Math.ceil(numberOfItems / 5);
		let pages = [];
		let arr = [];

		if (numberOfPages) {

			for (let i = 1; i <= numberOfPages; i++) {
				pages.push(i);
			}

		}

		if (pages.length > 4) {

			for (let i = 2; i < pages.length; i++) {

				if (pageNumber <= 3 && i <= 4) {
					arr.push(i);
				} else if (pageNumber >= pages.length - 1 && i >= pages.length - 3) {
					arr.push(i);
				} else if (i >= pageNumber - 1 && i <= pageNumber + 1) {
					arr.push(i);
				} else {

					if (pageNumber === i - 2 || pageNumber === i + 2) {
						arr.push('...')
					} else {

						if (i === 5 && i > pageNumber) {
							arr.push('...')
						}

						if (i === pages.length - 4 && i < pageNumber) {
							arr.push('...')
						}

					}

				}
			}

			pages = [pages[0], ...arr, pages[pages.length - 1]];
		} else {
			arr = [pages[1], pages[2]];
		}

		return (
			<PaginationContainer>
				<PaginationIconContainer
					marginRight={px(124)}
					elementDisplay={this.state.pageNumber !== 1}
					onClick={() => pageNumber > 0 ? this.setPage(this.state.pageNumber - 1) : undefined}
				>
					<Icon
						iconComponent={ChevronLeft}
						primaryColor={IconColor.BLUE}
						title="ikona"
					/>
				</PaginationIconContainer>
				{numberOfPages > 1 ? pages.map((pageNumber, i) => {
					return (
						<PaginationItem
							key={'item-' + i}
							background={pageNumber === '...' ? 'transparent' : palette.solidWhite}
							onClick={pageNumber === '...' ? () => undefined : () => this.setPage(pageNumber)}
							active={this.state.pageNumber === pageNumber}
						>
							{pageNumber}
						</PaginationItem>
					);
				}) : ''}
				<PaginationIconContainer
					marginLeft={px(124)}
					elementDisplay={this.state.pageNumber !== numberOfPages}
					onClick={() => pageNumber < numberOfPages ? this.setPage(this.state.pageNumber + 1) : undefined}
				>
					<Icon
						iconComponent={ChevronRight}
						primaryColor={IconColor.BLUE}
						title="ikona"
					/>
				</PaginationIconContainer>
			</PaginationContainer>
		);
	}

	renderFilters() {
		return (<FilterBox key={'filter-box-2'}>
			<Text as="h4">tematy</Text>
			<Separator />
			<HorizontalLine />
			<Separator />
			{this.state.categories.map((category, i) => {
				return this.state.openCategories || !this.state.openCategories && i < 5 ? <Box
					key={'category-' + i}
					style={{
						cursor: 'pointer',
						fontWeight: this.state.category === category.name ? 'bold' : 'normal'
					}}
					onClick={() => this.filterByCategory(category)}
					marginTop={Spacing.SPACE_16}
					marginBottom={Spacing.SPACE_16}
				>
					{category.title}
				</Box> : '';
			})}
			{this.state.categories.length > 5 ? <Button
				buttonType="tertiary"
				color={ButtonColor.BLUE}
				iconComponent={this.state.openCategories ? ChevronUp : ChevronDown}
				onClick={this.toggleCategories}
				style={{
					cursor: 'pointer'
				}}
				marginTop={Spacing.SPACE_16}
				marginBottom={Spacing.SPACE_16}
			>
				{this.state.openCategories ? 'ukryj' : 'pokaż wszystkie'}
			</Button> : ''}
		</FilterBox>);
	}

	renderArchive() {
		return (<FilterBox key={'filter-box-3'}>
			<Text as="h4">archiwum</Text>
			<Separator />
			<HorizontalLine />
			<Separator />
			{this.state.archiveData.data.length ? this.state.archiveData.data[0].months.map((month, i) => {
				return <Box
					key={'month-' + i}
					style={{
						cursor: 'pointer',
						fontWeight: month.active ? 'bold' : 'normal'
					}}
					onClick={() => this.filterByDate(this.state.archiveData.data[0].year, month)}
					marginTop={Spacing.SPACE_16}
					marginBottom={Spacing.SPACE_16}
				>
					{month.monthLabel} {this.state.archiveData.data[0].year}
				</Box>;
			}) : ''}
			{this.state.archiveData.data.length > 1 ? (<Select
				value={this.state.archiveData.selectValue}
				label='wybierz rok'
				items={this.state.archiveData.selectItems}
				onChange={value => this.updateState('selectValue', value)}
			/>) : ''}
			{this.state.archiveData.data.length && this.state.archiveData.selectValue ? this.state.archiveData.data.filter((data, i) => {
				return data.year === this.state.archiveData.selectValue
			}).map(data => {
				return data.months.map((month, i) => {
					return <Box
						key={'month-' + i}
						style={{
							cursor: 'pointer',
							fontWeight: month.active ? 'bold' : 'normal'
						}}
						onClick={() => this.filterByDate(data.year, month)}
						marginTop={Spacing.SPACE_16}
						marginBottom={Spacing.SPACE_16}
					>
						{month.monthLabel} {data.year}
					</Box>;
				});
			}) : ''}
		</FilterBox>);
	}

	renderNewsItem(item, index) {
		return (<NewsItem key={'item-' + index} data-index={index}>
			<ImageContainer data-index={index}>
				{index === 0 ? <Image src={item.image} alt={item.altText} title={item.altText} /> : <Image src={item.mobileImage} alt={item.altText} title={item.altText} />}
			</ImageContainer>
			<MobileImageContainer data-index={index}>
				<Image src={item.mobileImage} alt={item.altText} title={item.altText} />
			</MobileImageContainer>
			<TextContainer data-index={index}>
				<Inner>
					<TitleRow>
						<MarkContainer>
							{(item.popular === "true" || item.popular === true) ? <PopularMark>
								<Text as="small" color="solidWhite">popularne</Text>
							</PopularMark> : ''}
							{item.categories.map((category, i) => {
								return (<CategoryMark key={'category-' + i}>
									<Text as="small" color="solidWhite">{category.title}</Text>
								</CategoryMark>);
							})}
						</MarkContainer>
						<Text
							as="small"
							color={index === 0 ? 'solidWhite' : 'doveGrayUI'}
							marginLeft={Spacing.SPACE_8}
						>
							{item.date}
						</Text>
					</TitleRow>
					<Text
						as="h3"
						color={index === 0 ? 'solidWhite' : 'zeusBlackUI'}
						marginTop={Spacing.SPACE_16}
					>
						{item.title}
					</Text>
					<Text
						as="p"
						color={index === 0 ? 'solidWhite' : 'zeusBlackUI'}
						marginTop={Spacing.SPACE_8}
						marginBottom={Spacing.SPACE_16}
					>
						{item.newsTeaser}
					</Text>
					{generateLink(this.props.prefix + item.path, '', '', (
						<Button buttonType="tertiary" color={ButtonColor.BLUE}>czytaj więcej</Button>
					), this.state.offline)}
				</Inner>
				<MobileInner>
					<TitleRow>
						<MarkContainer>
							{item.popular ? <PopularMark>
								<Text as="small" color="solidWhite">popularne</Text>
							</PopularMark> : ''}
							{item.categories.map((category, i) => {
								return (<CategoryMark key={'category-' + i}>
									<Text as="small" color="solidWhite">{category.title}</Text>
								</CategoryMark>);
							})}
						</MarkContainer>
						<Text
							as="small"
							color={'doveGrayUI'}
						>
							{item.date}
						</Text>
					</TitleRow>
					<Text
						as="h3"
						color={'zeusBlackUI'}
						marginTop={Spacing.SPACE_16}
					>
						{item.title}
					</Text>
					<Text
						as="p"
						color={'zeusBlackUI'}
						marginTop={Spacing.SPACE_8}
						marginBottom={Spacing.SPACE_16}
					>
						{item.newsTeaser}
					</Text>
					{generateLink(this.props.prefix + item.path, '', '', (
						<Button buttonType="tertiary" color={ButtonColor.BLUE}>czytaj więcej</Button>
					), this.state.offline)}
				</MobileInner>
			</TextContainer>
		</NewsItem>);
	}

	renderNewsList() {
		return (<ListBox>
			{this.state.items.filter(item => item.visible && item.inCurrentPage).map((item, i) => {
				return this.renderNewsItem(item, i);
			})}
			{this.renderPagination()}
		</ListBox>);
	}

	render() {
		return (
			<Container>
				<FiltersNav onClick={this.openFilters}>
					<Icon
						iconComponent={Filter}
						primaryColor={IconColor.BLUE}
						title="ikona"
					/>
				</FiltersNav>
				<ListContainer>
					{this.renderNewsList()}
					<FilterContainer open={this.state.openFilters}>
						{this.renderPopular()}
						{this.renderFilters()}
						{this.renderArchive()}
					</FilterContainer>
				</ListContainer>
			</Container>
		);
	}

}

export default News;
