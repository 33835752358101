/* prod environment config 
 * replaces environment.js during test build process
 */
export const environment = {
	siteUrl:'https://mleasing.pl',
	type: 'site',
	offlineApiPath: '/api/pages/',
	onlineApiNavPath: 'https://mleasing.pl/api/navigation/',
	offlineApiNavPath: '/api/navigation/',
	apiSearchPath: 'https://mleasing.pl/api/search/',
	apiSearchMainFileName: 'wyszukiwarka_ogolna.csv',
	apiSearchServiceFileName: 'wyszukiwarka_obsluga.csv',
	apiNavFileName: 'data.json',
	apiNavCategoriesFileName: 'categories.csv',
	apiNavExternalLinksFileName: 'external_links.csv',
	// apiSearchResultsPath: 'https://search.interconsystems.pl/s/90FE73/default/',
	apiSearchResultsPath: 'https://mleasing.pl/szukaj/default/',
	onlineApiPathsPath: 'https://mleasing.pl/api/paths/',
	offlineApiPathsPath: '/api/paths/',
	apiPathsFileName: 'data.json',
	onlineApiPath: 'https://mleasing.pl/',
	onlineApiFileName: 'index.html.json',
	cmsExportPath: '/sites/mleasing',
	onlineMAutoApiPath: 'https://mleasing.pl/api/mauto/',
	offlineMAutoApiPath: 'https://mleasing.pl/mleasing/api/mauto/',
	onlineCatalog: '',
	agenciesApiPath: 'api/maps/filie-i-oddzialy-mleasing.csv',
	servicePointsApiPath: 'api/maps/warsztaty-i-serwisy-mleasing.csv',
	registrationNumbersApiPath: 'api/maps/numery-rejestracyjne-mleasing.csv',
	// googleMapsKey: 'AIzaSyAmOa-3O-pwynmMX8SGl6Yd0SgaLFsGFa8',
	googleMapsKey: 'AIzaSyAB7xewUd5_qD60Hp4e31vHBv7FJJ0djGo',
	reCaptchaKey: '6LeEhYwaAAAAAHlav9sF9nrnLtOfVSCGMB_bqvP5',
	// php and config urls
	calculatorDataFile: 'https://mleasing.pl/api/calc/pojazdy_kalk.csv',
	calculatorSendUrl: 'https://mleasing.pl/ajax/Form/CalcForm/',
	calculatorUploadUrl: 'https://mleasing.pl/ajax/Form/upload/',
	contactFormDataFile: 'https://mleasing.pl/.includes/subjects-contact-form.html.i',
	contactFormSendUrl: 'https://mleasing.pl/ajax/Form/ContactForm/',
	financingFormDataFile: 'https://mleasing.pl/.includes/offer-form-leasing-types.html.i',
	financingFormRealEstateSendUrl: 'https://mleasing.pl/ajax/form/realEstateForm/',
	financingFormOfferSendUrl: 'https://mleasing.pl/ajax/Form/OfferForm/',
	fastCallUrl: 'https://mleasing.pl/ajax/FastCall/add/',
	mapSearchUrl: 'https://mleasing.pl/ajax/Search/byPlateAndVIN/',
	siteHeight: 1400
};
