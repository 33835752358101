/*!
 * mLeasing, banner portlet :: 22/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment';
import {Button} from '@mbank-design/design-system/components';
import {eventLogger, rem, px} from '@mbank-design/design-system/utils';
import {Text} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {Icon} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import {Tick} from '@mbank-design/design-system/icons';
import {Stripe} from '@mbank-design/design-system/components';
import {ArrowLeft} from '@mbank-design/design-system/icons';
import {Spacing} from '@mbank-design/design-system/enums';
import {ButtonColor, StripeHeight} from '@mbank-design/design-system/enums';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {mapLinks} from '../../utils/map-links';
import {replaceCmsContentLinks} from '../../utils/replace-cms-content-links';
import {componentsFromHtml} from '../../utils/components-from-html';
import {generateLink} from '../../utils/generate-link';

let site = '';
let filePath = [];

const version = 'v: 1.1.0 :: r. 18/12/23 @ pp';
// use styled components to pass styles for layout purposes
const svg = {
	arrow: '<svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 9.828 16.828"><path id="Path" d="M0,0,7,7,0,14" transform="translate(1.414 1.414)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="2"/></svg>'
}
const Outer = styled.div` 
	position: relative;
	display: block;
`;
const BannerOuter = styled(Outer)`
	position: relative;
	overflow: hidden;

	@media screen and (max-width: ${px(460)}) {
		background-color: ${props => props.titleOverImage ? 'transparent' : '#000'};
	}
`;
const ImageContainer = styled.div`

	&::after {
		content: '';
		display: ${props => props.titleOverImage ? 'none' : 'block'};
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: ${props => props.withoutGradient ? '' : 'rgba(0, 0, 0, 0) linear-gradient(to left, transparent, rgb(0, 0, 1) 90%, rgb(0, 0, 0)) repeat scroll 0% 0%'};
	}

	&.without::after {
		background: none;
	}

	@media screen and (max-width: ${px(460)}) {
		position: ${props => props.textBox ? 'static' : 'absolute'};
		width: 100%;
		&::after {
			background: ${props => props.withoutGradient ? '' : 'rgba(0, 0, 0, 0) linear-gradient(to bottom, transparent, rgb(0, 0, 1) 90%, rgb(0, 0, 0)) repeat scroll 0% 0%'};
		}
	}
`;
const BannerImage = styled.img`
	display: block;
	min-width: ${px(1600)};
	width: 100%;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		transform: translateX(-30%);
	}

	@media screen and (max-width: ${px(800)}) {
		transform: translateX(-40%);
	}
	
	@media screen and (max-width: ${px(460)}) {
		display: none;
		max-height: 500px;
		min-height: 196px;
	}
	`;
const BannerImageMobile = styled.img`
	display: none;
	width: auto;
	height: auto;
	min-width: 100%;
	transform: translateX(-50%);
	
	@media screen and (max-width: ${px(460)}) {
		display: block;
		max-height: 500px;
		min-height: 196px;
	}
`;
const BannerContainer = styled.div`
	position: ${props => props.image ? 'absolute' : 'relative'};
	padding-top: ${props => props.paddingTop};
	padding-bottom: ${props => props.paddingBottom};
	top: 0;
	bottom: 0;
	left: 50%;
	left: ${props => props.image ? '50%' : 'auto'};
	transform: translate(${props => props.image ? '-50%' : '0'});
	z-index: 1;
	min-height: 100%;
	max-width: ${px(1315)};
	width: 100%;
	margin-top: ${Spacing.SPACE_0};
	margin-right: auto;
	margin-bottom: ${Spacing.SPACE_0};
	margin-left: auto;

	${({breadcrumb, textBoxLink}) => (breadcrumb && !textBoxLink) && `
		display: flex;
		align-items: flex-end;
	`}

	@media screen and (min-width: ${px(1600)}) {
		padding-top: ${props => !props.image ? props.paddingTop : 'calc(100vw * ' + (props.paddingTop === '81px' ? 0.050625 : 0.045) + ')'};
		padding-bpttom: ${props => !props.image ? props.paddingBottom : 'calc(100vw * ' + (props.paddingBottom === '81px' ? 0.050625 : 0.045) + ')'};
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-top: ${px(Spacing.SPACE_40)};
		padding-bottom: ${px(Spacing.SPACE_40)};
		padding-bottom: ${props => props.textBox ? px(Spacing.SPACE_20) : px(Spacing.SPACE_40)};
	}
	@media screen and (max-width: ${px(460)}) {
		position: relative;
		left: auto;
		transform: none;
		min-height: 196px;
		display: ${props => props.align === 'center' ? 'flex' : 'block'};
		align-items: center;
		justify-content: center;
	}
`;
const Inner = styled.div`
	max-width: ${props => props.maxWidth || 'unset'};

	@media screen and (max-width: ${px(1350)}) {
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: 100%;
		&:not(.breadcrumb) {
			text-align: center;
		}
	}

`;
const BannerTitle = styled(Box)`
	&.breadcrumb {
		width: 850px;
	}
	@media screen and (max-width: ${px(900)}) {
		&.breadcrumb {
			width: auto;
		}
	}
`;
const FlexContainer = styled(Box)`
	display: flex;
	flex-wrap: nowrap;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: column;
	}
`;
const FlexRow = styled(FlexContainer)`
	margin-bottom: {props => px(props.marginBottom) || px(Spacing.SPACE_12)}
	justify-content: ${props => props.justifyContent};
	align-items: ${props => props.alignItems};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: row;
		padding: ${props => props.type === 'mobile-padding' ? `${px(Spacing.SPACE_12)} ${px(Spacing.SPACE_12)}` : `${px(Spacing.SPACE_0)} ${px(Spacing.SPACE_0)}`};
	}
`;
const MainTextBox = styled.div`
	position: absolute;
	bottom: 10%;
	background-color: ${palette.white30};
	max-width: ${px(600)};
	text-align: left;

	& .title {
		font-size: ${rem(2.3)};
	}
	@media screen and (max-width: ${px(630)}) {
		max-width: unset;
		width: calc(100% - 32px);
	}
	@media screen and (max-width: ${px(480)}) {
		& .title {
			font-size: 1.5rem;
			line-height: 1.5;
		}
`;
const MainTextBox2 = styled(MainTextBox)`
	padding: ${px(Spacing.SPACE_32)};
`;
const BackLinkContainer = styled.div`
	position: absolute;
	top: ${px(Spacing.SPACE_16)};
	&.breadcrumb {
		line-height: 1;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		&:not(.breadcrumb) {
			top: -${px(Spacing.SPACE_32)};
			left: ${px(Spacing.SPACE_16)};
		}
	}
`;
const backLinkStyles = `
	display: flex;
	align-items: center;
	color: ${palette.solidWhite};
	&:hover {
		color: ${palette.solidWhite};
	}
`;
const breadcrumbStyles = `
	color: ${palette.solidWhite};
	&:hover {
		color: ${palette.solidWhite};
	}
`;
const TitleContainer = styled(Box)`
	margin: 0 auto;
	max-width: ${px(866)};
	padding-top: ${px(Spacing.SPACE_24)};
	text-align: center;
`;
const BannerWrapper = styled.div`
	position: relative;
	background-color: ${palette.wildSandGray};
`;
const LinkWrapper = styled.div`
	position: ${props => props.titleOverImage ? 'absolute' : 'static'};
	top: calc(100% - ${px(Spacing.SPACE_76)});
	left: 50%;
	transform: translate(${props => props.titleOverImage ? '-50%' : '0'});
	margin-right: ${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		width: ${props => props.titleOverImage ? 'auto' : '100%'};
	}
`;
const TextItem = styled.div`
	color: ${props => !props.textBox ? palette.solidWhite : palette.zeusBlackUI};
`;
const TextBox = styled.div`
	color: ${props => !props.textBox ? palette.solidWhite : palette.zeusBlackUI};
	margin-bottom: ${props => props.textBox ? px(Spacing.SPACE_0) : px(Spacing.SPACE_32)};
	max-width: ${px(500)};
	& * {
		line-height: 2;
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		max-width: 100%;
	}
`;
const LinkBox = styled.div`
 	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
`;
const buildState = (props) => {

	let textBox = false;
	let textBoxLink = '';
	let title = '';
	let text = '';
	let linkName = '';
	let link = '';
	let image = '';
	let mobileImage = '';
	let items = [];
	let breadcrumb = false;
	let isTopBanner = false;
	let withoutGradient = false;
	let bottomText = '';
	let bottomTextLink = '';
	let backLinkName = '';
	let backLink = '';
	let titleOverImage = false;
	let altText = '';
	let color = 'white';

	if (props.offline) {
		textBox = props.data && props.data.textInBox == 'true' ? true : false;
		textBoxLink = props.data && props.data.textInBoxLink && mapLinks([{
			url: props.data.textInBoxLink,
			navText: props.data.textInBoxLink
		}], props.offline)[0].url;
		title = props.data ? props.data.title : '';
		text = props.data ? props.data.text : '';
		linkName = props.data ? props.data.linkName : '';
		link = props.data && props.data.link && mapLinks([{
			url: props.data.link,
			navText: props.data.link
		}], props.offline)[0].url;
		image = props.data ? props.data.image : '';
		mobileImage = props.data ? props.data.mobileImage : '';
		altText = props.data.altText || '';
		items = props.data && props.data.items ? props.data.items : [];
		breadcrumb = props.data && props.data.breadcrumb == 'true' ? true : false;
		isTopBanner = props.data && props.data.isTopBanner;
		withoutGradient = props.data && props.data.withoutGradient == 'true' ? true : false;
		titleOverImage = props.data.titleOverImage === 'true' ? true : false;
		bottomTextLink = props.data && props.data.bottomTextLink && mapLinks([{
			url: props.data.bottomTextLink,
			navText: props.data.bottomTextLink
		}], props.offline)[0].url;
		bottomText = props.data ? props.data.bottomText : '';
		backLinkName = props.data ? props.data.backLinkName : '';
		color = props.data ? props.data.color : 'white';
		backLink = props.data && props.data.backLink && mapLinks([{
			url: props.data.backLink,
			navText: props.data.backLink
		}], props.offline)[0].url;
	}
	else {
		try {
			let obj = props.data['BannerData']['Banner'];
			let textLinks = [];

			if (Array.isArray(obj.Text.links.link)) {
				textLinks = obj.Text.links.link.map(link => {

					return {
						name: link.name,
						target: link.target || link.anchor
					};
				});
			}
			else if (obj.Text.links !== '') {
				textLinks = [{
					name: obj.Text.links.link.name,
					target: obj.Text.links.link.target || obj.Text.links.link.anchor
				}];
			}
			if (obj.TextItem) {
				if (Array.isArray(obj.TextItem)) {
					items = obj.TextItem.map(item => {

						let textLinks = [];

						if (Array.isArray(item.links.link)) {
							textLinks = item.links.link.map(link => {

								return {
									name: link.name,
									target: link.target || link.anchor
								};
							});
						}
						else if (item.links !== '') {
							textLinks = [{
								name: item.links.link.name,
								target: item.links.link.target || item.links.link.anchor
							}];
						}
						return replaceCmsContentLinks(item.content, textLinks);
					});
				}
				else if (typeof obj.TextItem === 'string') {
					items = ['<p>' + obj.TextItem + '</p>'];
				}
				else {
					let textLinks = [];

					if (Array.isArray(obj.Text.links.link)) {
						textLinks = obj.Text.links.link.map(link => {

							return {
								name: link.name,
								target: link.target || link.anchor
							};
						});

					}
					else if (obj.Text.links !== '') {
						textLinks = [{
							name: obj.Text.links.link.name,
							target: obj.Text.links.link.target || obj.Text.links.link.anchor
						}];
					}
					items = [replaceCmsContentLinks(obj.TextItem.content, textLinks)];
				}

				//items = Array.isArray(obj.TextItem) ? obj.TextItem : [obj.TextItem];
			} else {
				items = [];
			}
			let linkUrlQuery = obj.Link.link.query ? ('?' + obj.Link.link.query) : '';
			textBox = obj.TextInBox;
			textBoxLink = obj.TextInBoxLink ? mapLinks([{
				url: obj.TextInBoxLink.link.target,
				navText: obj.TextInBoxLink
			}], props.offline)[0].url : '';
			title = obj.Title;
			// replace link href attribute representations with actual paths
			text = replaceCmsContentLinks(obj.Text.content, textLinks);
			linkName = obj.LinkName;
			color = obj.Color || 'white';
			link = obj.Link ? mapLinks([{
				url: obj.Link.link.target + linkUrlQuery,
				navText: obj.LinkName
			}], props.offline)[0].url : '';
			image = obj.Image ? site + obj.Image.link.target.replace(environment.cmsExportPath, '') : '';
			mobileImage = obj.MobileImage ? site + obj.MobileImage.link.target.replace(environment.cmsExportPath, '') : '';
			altText = obj.AltText || '';
			breadcrumb = obj.Breadcrumb;
			isTopBanner = obj.IsTopBanner;
			withoutGradient = obj.WithoutGradient;
			titleOverImage = props.data.TitleOverImage === 'true' ? true : false;
			bottomText = obj.BottomText || '';
			bottomTextLink = obj.BottomTextLink && mapLinks([{
				url: obj.BottomTextLink.link.target,
				navText: obj.BottomTextLink.link.target
			}], props.offline)[0].url;
			backLinkName = obj.BackLinkName;
			backLink = obj.BackLink ? mapLinks([{
				url: obj.BackLink.link.target,
				navText: obj.BackLinkName
			}], props.offline)[0].url : '';
		}
		catch (err) {
			console.log(err);
		}
	}
	return {
		offline: props.offline,
		pageTitle: props.pageTitle,
		pageType: props.pageType,
		textBox: textBox,
		textBoxLink: textBoxLink,
		title: title,
		text: text,
		linkName: linkName,
		link: link,
		image: image,
		mobileImage: mobileImage,
		color: color,
		altText: altText,
		breadcrumb: breadcrumb,
		isTopBanner: isTopBanner,
		withoutGradient: withoutGradient,
		bottomText: bottomText,
		bottomTextLink: bottomTextLink,
		backLink: backLink,
		backLinkName: backLinkName,
		titleOverImage: titleOverImage,
		uuid: props.uuid,
		items: items
	};
};

class Banner extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.generateSeparator = this.generateSeparator.bind(this);
		this.renderTextItemIcon = this.renderTextItemIcon.bind(this);
		this.renderText = this.renderText.bind(this);
		this.changeUrlSizeImages = this.changeUrlSizeImages.bind(this);

		this.state = buildState(props);
	}

	getVersion() {

		return version;
	}

	componentDidMount() {

		let part = '';
		filePath = window.location.pathname.substring(1).replace(/\/([\w-_]+\.html)?$/, '').split('/').map((folder) => {

			part = (part ? part : '/') + (folder ? folder + '/' : '');
			return part;
		});

		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('banner');

			if (window.origin.includes('localhost')) {
				site = environment.siteUrl;
			}
		}
		if (environment.type == 'blog') site = '/blog';
		if (this.state.mobileImage) {
			const i = this.state.image;
			const mi = this.state.mobileImage;

			this.changeUrlSizeImages(i, mi);
			window.addEventListener('resize', () => {this.changeUrlSizeImages(i, mi)});
		}
	}

	static getDerivedStateFromProps(props, state) {

		return buildState(props);
	}

	changeUrlSizeImages(i, mi) {

		if (window.innerWidth <= 460) {
			this.setState({image: mi});
		} else {
			this.setState({image: i});
		}
	}

	renderTextItemIcon(item) {

		if (item) {
			return <Icon iconComponent={Tick} primaryColor={IconColor.BLUE} title="ikona" />;
		}
	}

	generateSeparator() {

		return (<Text as="span" marginHorizontal={10} style={{verticalAlign: `middle`}} dangerouslySetInnerHTML={{__html: svg.arrow}}></Text>);
	}

	renderText() {

		return (<>
			{!this.state.titleOverImage ? (<BannerTitle className={this.state.breadcrumb ? 'breadcrumb' : ''}>
				<Text
					as={this.state.isTopBanner ? 'h1' : 'h2'}
					className='title'
					color={this.state.color === 'black' || this.state.textBox ? 'zeusBlackUI' : 'solidWhite'}
					marginTop={this.state.breadcrumb ? Spacing.SPACE_100 : Spacing.SPACE_0}
					marginBottom={this.state.text || this.state.items.length || this.state.link || this.state.bottomTextLink ? Spacing.SPACE_32 : Spacing.SPACE_0}
				>
					{this.state.title}
				</Text></BannerTitle>) : ''}
			{this.state.text ? <TextBox
				as="div"
				textBox={this.state.textBox}
			>
				{componentsFromHtml(this.state.text, this.state.offline)}
			</TextBox> : ''}
			{this.state.items && this.state.items.length ? this.state.items.map((item, j) => {

				return (<Box as="div" key={'item-' + j} marginBottom={Spacing.SPACE_12}>
					<FlexRow>
						{this.renderTextItemIcon(item)}
						<TextItem
							textBox={this.state.textBox}
						>
							{componentsFromHtml(item, this.state.offline)}
						</TextItem>
					</FlexRow>
				</Box>);
			}) : ''}
		</>);
	}

	render() {
		return (
			<BannerWrapper id={'banner-ii'} className={'test-banner'}>
				{this.state.titleOverImage ? <TitleContainer>
					<Text
						as={this.state.isTopBanner ? 'h1' : 'h2'}
						marginBottom={this.state.text || this.state.items.length || this.state.link || this.state.bottomTextLink ? Spacing.SPACE_32 : Spacing.SPACE_0}
					>
						{this.state.title}
					</Text>
				</TitleContainer> : ''}
				<BannerOuter
					as="div"
					className="outer"
					titleOverImage={this.state.titleOverImage}
				>
					{this.state.image ?
						<ImageContainer
							className={this.state.withoutGradient ? "without" : ""}
							textBox={this.state.textBox}
						>
							<BannerImage
								className="image"
								src={this.state.image}
								alt={this.state.altText}
								title={this.state.title}
								titleOverImage={this.state.titleOverImage}
							/>
							<BannerImageMobile
								className="image mobile-image"
								src={this.state.mobileImage ? this.state.mobileImage : this.state.image}
								alt={this.state.altText}
								title={this.state.title}
								titleOverImage={this.state.titleOverImage}
							/>
						</ImageContainer>
						: ''}
					<BannerContainer
						className="container"
						paddingTop={this.state.backLink ? (this.state.image ? px(Spacing.SPACE_72) : px(Spacing.SPACE_56)) : px(81)}
						paddingBottom={this.state.backLink ? (this.state.image ? px(Spacing.SPACE_72) : px(Spacing.SPACE_0)) : px(81)}
						image={this.state.image}
						align={!this.state.text && !this.state.items.length && !this.state.link && !this.state.bottomTextLink ? 'center' : 'top'}
						textBox={this.state.textBox}
						textBoxLink={this.state.textBoxLink}
						breadcrumb={this.state.breadcrumb}
					>
						<Inner as="div" className={'inner' + (this.state.breadcrumb ? ' breadcrumb' : '')} maxWidth={!this.state.isTopBanner ? px(530) : '100%'}>
							{this.state.backLink ? (<BackLinkContainer>
								{generateLink(this.state.backLink, null, backLinkStyles, (
									<>
										<Icon
											iconComponent={ArrowLeft}
											primaryColor={this.state.color === 'black' ? IconColor.BLACK : IconColor.WHITE}
											title="ikona"
										/>
										<Text
											as="small"
											marginLeft={Spacing.SPACE_16}
											color={this.state.color === 'black' ? 'zeusBlackUI' : 'solidWhite'}
										>
											{this.state.backLinkName}
										</Text>
									</>
								), this.state.offline)}
							</BackLinkContainer>) : ''}
							{this.state.breadcrumb ?
								<BackLinkContainer className='breadcrumb'>
									{generateLink(`/`, <Text
										as="small"
										color={'solidWhite'}
									>Strona główna</Text>, breadcrumbStyles, null, this.state.offline)}
									{/*TO DO: automatic generating folder path title (below this part is title 'Blog' hardcoded) */}
									{/*filePath.map((part) => {

										if (part) {
											if (part !== window.location.pathname) {
												let url = environment.onlineApiPath.slice(0, -1) + part + 'index.html.json';

												if (this.state.offline) {
													url = part + 'index.html?__json=true';
												}
												fetch(url)
													.then(res => res.json())
													.then(json => {

														return (generateLink(part, (<Text
														as="small"
														color={'solidWhite'
														}>{json.title}</Text>), breadcrumbStyles, null, this.state.offline));
													});
											}
										}
									})*/}
									{this.generateSeparator()}
									{generateLink('/blog/', (<Text
										as="small"
										color={'solidWhite'
										}>Blog</Text>), breadcrumbStyles, null, this.state.offline)}
									{this.generateSeparator()}
									{generateLink(`${filePath[filePath.length - 1]}`, <Text
										as="small"
										color={'solidWhite'}
									>{this.state.title || this.state.pageTitle}</Text>, breadcrumbStyles, '', this.state.offline)}
								</BackLinkContainer> : ''
							}
							{this.state.textBox ?
								(this.state.textBoxLink ?
									<MainTextBox as="div" className="text-box">
										{generateLink(this.props.prefix + this.state.textBoxLink, '', `padding: ${px(Spacing.SPACE_32)}; display: block`, (<>
											<Stripe style={{position: ` absolute`, top: px(-10), left: 0, right: 0, width: `80%`, height: px(10), margin: 'auto'}} />
											{this.renderText()}
											<Text as="div" style={{position: `absolute`, bottom: px(-10), left: '60%', right: '10%', height: px(10), margin: 'auto', overflow: 'hidden'}}>
												<Stripe style={{position: `absolute`, right: '0', width: '230%', height: px(10)}} /></Text></>), this.state.offline
										)}
									</MainTextBox>
									:
									<MainTextBox2 as="div" className="text-box">
										<Stripe style={{position: ` absolute`, top: px(-10), left: 0, right: 0, width: `80%`, height: px(10), margin: 'auto'}} />
										{this.renderText()}
										<Text as="div" style={{position: `absolute`, bottom: px(-10), left: '60%', right: '10%', height: px(10), margin: 'auto', overflow: 'hidden'}}>
											<Stripe style={{position: `absolute`, right: '0', width: '230%', height: px(10)}} /></Text>
									</MainTextBox2>
								)
								: this.renderText()}
							<LinkBox>
								{this.state.link ? <LinkWrapper titleOverImage={this.state.titleOverImage}>{generateLink(this.props.prefix + this.state.link, '', '', (
									<Button buttonType='primary' onClick={eventLogger.click}>{this.state.linkName}</Button>
								), this.state.offline)}</LinkWrapper> : ''}
								{this.state.bottomTextLink ? generateLink(this.props.prefix + this.state.bottomTextLink, '', '', (
									<Button buttonType='tertiary' color={ButtonColor.WHITE} onClick={eventLogger.click}>{this.state.bottomText}</Button>
								), this.state.offline) : ''}
							</LinkBox>
						</Inner>
					</BannerContainer>
				</BannerOuter>
			</BannerWrapper>
		);
	}

}

export default Banner;
