/*!
 * mLeasing, log articles list portlet :: 05/11/23
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../../environment.js';
import {Text} from '@mbank-design/design-system/components';
import {Button} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {Icon} from '@mbank-design/design-system/components';
import {ChevronRight} from '@mbank-design/design-system/icons';
import {ChevronLeft} from '@mbank-design/design-system/icons';
import {IconColor} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import {ButtonColor} from '@mbank-design/design-system/enums';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import Container from '../../components/container';
import './blog-articles-list.css';

const version = 'v: 0.1.0 :: r. 05/11/23 @ pp';

let site = '';
let displayedLatest = 4;	//after downloading the page four latest articles are displayed

const ListContainer = styled.div`
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-left: ${px(Spacing.SPACE_16)};
		padding-right: ${px(Spacing.SPACE_16)};
	}
`;
const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
`;
const PaginationItem = styled.div`
	margin: 0 8px;
	cursor: pointer;
	padding: 12px;
	width: 40px;
	text-align: center;
	color: ${props => props.active ? palette.solidWhite : palette.endeavourBlueUI};
	background-color: ${props => props.active ? palette.endeavourBlueUI : props.background};
`;
const PaginationIconContainer = styled.div`
	display: ${props => props.elementDisplay ? 'flex' : 'none'};
	align-items: center;
	margin-right: ${props => props.marginRight || 0};
	margin-left: ${props => props.marginLeft || 0};
	cursor: pointer;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: 0;
		margin-left: 0;
	}
`;

const buildState = (props, state) => {

	let title = '';
	let type = 'latest';

	if (props.offline) {
		title = props.data.title;
		type = props.data.type;
	}
	else {
		try {
			let obj = props.data['BlogArticlesListData']['BlogArticlesList'];

			title = obj.Title;
			type = obj.ListType;
		}
		catch (err) {
			console.log(err);
		}
	}
	return {
		offline: props.offline,
		pageType: props.pageType,
		type: type,
		title: title,
		items: state ? state.items : [],
		recommended: state ? state.recommended : [],
		popular: state ? state.popular : [],
		pageNumber: state ? state.pageNumber : 1,
		displayed: displayedLatest ? displayedLatest : 4	//at the beginning there are four latest articles diaplayed
	};
};

class BlogArticlesList extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.getItems = this.getItems.bind(this);
		this.getMore = this.getMore.bind(this);
		this.renderLatestList = this.renderLatestList.bind(this);
		this.renderRecommendedList = this.renderRecommendedList.bind(this);
		this.generateArticleBox = this.generateArticleBox.bind(this);
		this.renderGetMore = this.renderGetMore.bind(this);
		this.updateState = this.updateState.bind(this);
		this.renderPagination = this.renderPagination.bind(this);
		this.setPage = this.setPage.bind(this);

		this.state = buildState(props);
	}

	getVersion() {

		return version;
	}

	componentDidMount() {

		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('blog-articles-list');

			if (window.origin.includes('localhost')) {
				site = environment.siteUrl;
			}
		}
		if (!window.origin.includes('localhost') && environment.type == 'blog') {
			site = '/blog';
		}
		this.getItems();
	}

	getItems() {

		const getImage = ((items) => {

			let image = null, img = {}, src = {path: '', alt: ''};

			if ((typeof items === 'object' && !Array.isArray(items)) || typeof items === 'string') {	//item = String only offline 
				src.path = items;

				if (!this.state.offline) {
					src.path = src.path.link.target.replace(environment.cmsExportPath, '');
				}
			}
			else if (Array.isArray(items) && items.length) {
				for (let item of items) {
					if (item.Choice?.Images) {
						if (item.Choice.Images?.Image) {
							image = item.Choice.Images.Image;

							if (image) {
								if (Array.isArray(image)) {
									img = image[0];
								}
								else {
									img = image;
								}
								if (img.MobileImage) {
									src.path = img.MobileImage;

									if (!this.state.offline) {
										src.path = src.path.link.target.replace(environment.cmsExportPath, '');
									}
								}
								if (img.AltText) {
									src.alt = img.AltText;
								}
							}
						}
					}
				}
			}
			return src;
		});

		// const url = 'https://test-mleasing11.qrqs.eu/api/paths/data.json'
		const url = (this.state.offline ? environment.offlineApiPathsPath : environment.onlineApiPathsPath) + environment.apiPathsFileName;
		let folders = ['blog', 'blog-mauto'];
		if (environment.type == 'blog') folders = ['blog-mauto'];
		if (environment.type !== 'blog') folders = ['blog'];

		for (let i = 0; i < folders.length; i++) {
			const folder = folders[i];

			if (window.location.href.match("/" + folder + "/") || environment.type == 'blog') {
				fetch(url)
					.then(res => res.json())
					.then(json => {

						let paths = json.filter(path => path.match("^\/" + folder + "\/.+") && !path.match(/index.html/) && !path.match("^\/" + folder + "\/$"));
						
						let items = [];
						let rootType = '';
						Promise.all(paths.map(path => {
							if (environment.type == 'blog' && path.match(/\/blog-mauto\//)) {
								rootType = '/blog'
							}
							if (environment.type !== 'blog' && path.indexOf("blog-mauto") > -1) {
								return false;
							}
							
							let url = environment.onlineApiPath.slice(0, -1) + rootType + path + 'index.html.json';

							if (this.state.offline) {
								// url = path + '?__json=true';
								url = path + 'index.html?__json=true';
							}
							return fetch(url)
								.then(res => res.json())
								.then(json => {

									let article;
									let obj;

									for (let key in json) {
										if (key.match(/blog-article(?!s)/)) {
											article = json[key];

											let title = '';
											let teaser = '';
											let author = '';
											let addInfo = '';
											let avatar = '';
											let recommended = false;
											let popular = false;
											let date = '';
											let image = '';

											if (this.state.offline) {
												obj = JSON.parse(article);
												let arr = [];

												addInfo = obj.addInfo;
												avatar = obj.avatar;
												author = obj.author;
												teaser = obj.teaser;
												title = obj.title;
												recommended = obj.recommended === 'true' ? true : false;
												popular = obj.popular === 'true' ? true : false;
												date = obj.date;
												image = getImage(obj.teaserImage || obj.item);
											}
											else {
												obj = article['BlogArticleData'];

												if (obj) {
													obj = obj['BlogArticle'];

													title = obj.Title;
													author = obj.Author;
													avatar = obj.Avatar ? obj.Avatar.link.target.replace(environment.cmsExportPath, '') : '';
													addInfo = obj.AddInfo;
													teaser = obj.Teaser;
													image = getImage(obj.TeaserImage || obj.Item);	//as teaser img can be specially inserted teaser image or first image from 'Images' section
													recommended = obj.Recommended;
													popular = obj.Popular;
													date = obj.Date;
												}
											}
											if (obj) {
												items.push({
													title: title,
													author: author,
													addInfo: addInfo,
													avatar: avatar,
													teaser: teaser,
													path: path,
													image: image,
													recommended: recommended,
													popular: popular,
													date: date,
													visible: true
												});
											}
										}
									}
								})
								.catch(err => console.log(err));
						})).then((res) => {

							// sort news by date
							const quickSort = (arr) => {

								const sort = (arr) => {

									let sorted = [arr[0]];
									let bigger = [];
									let lower = [];

									arr.forEach(elm => {

										// js date needs reverted order 
										let elmDate = new Date(elm.date);
										let firstElmDate = new Date(sorted[0].date);

										if (elmDate > firstElmDate) {
											bigger.push(elm);
										}
										if (elmDate < firstElmDate) {
											lower.push(elm);
										}
									});

									if (lower.length) {
										lower = sort(lower);
									}
									if (bigger.length) {
										bigger = sort(bigger);
									}
									sorted = lower.concat(sorted).concat(bigger);
									return sorted;
								}
								return sort(arr);
							}

							let sortedItems = quickSort(items).reverse();
							let recommendedIndex = 0;
							sortedItems = sortedItems.map((item, i) => ({ // paginate on first render
								title: item.title,
								author: item.author,
								addInfo: item.addInfo,
								avatar: item.avatar,
								date: item.date,
								image: item.image,
								path: (environment.type == 'blog' ? item.path.replace('/blog-mauto/blog/', '/') : item.path),
								recommended: item.recommended,
								popular: item.popular,
								teaser: item.teaser,
								visible: item.visible,
								index: i,
								inCurrentPage: item.recommended && recommendedIndex++ < 3 ? true : false	//there are three articles displayed at one (& first) page
							}));

							this.setState({
								offline: this.state.offline,
								pageType: this.state.pageType,
								title: this.state.title,
								items: sortedItems
							});
						});
					})
					.catch(err => console.log(err));
			}
		}
	}

	static getDerivedStateFromProps(props, state) {

		return buildState(props, state);
	}

	updateState(key, value) {

		this.setState({
			offline: this.state.offline,
			pageType: this.state.pageType,
			title: this.state.title,
			type: key === 'type' ? value : this.state.type,
			type: key === 'displayed' ? value : this.state.displayed,
			items: key === 'items' ? value : this.state.items,
			recommended: this.state.recommended,
			popular: this.state.popular,
			pageNumber: key === 'pageNumber' ? value : this.state.pageNumber
		});
	}

	setPage(pageNumber) {

		this.updateState('pageNumber', pageNumber);

		let itemIndex = 0;

		let timeout = setTimeout(() => {

			this.updateState('items', this.state.items.map((item, i) => {

				let inCurrentPage = false;

				if (item.visible && item.recommended) {
					inCurrentPage = itemIndex >= (pageNumber - 1) * 3 && itemIndex < pageNumber * 3;	//one page contains three articles
					itemIndex++;

				}
				return {
					title: item.title,
					author: item.author,
					addInfo: item.addInfo,
					avatar: item.avatar,
					date: item.date,
					image: item.image,
					path: (environment.type == 'blog' ? item.path.replace('/blog-mauto/blog/', '/') : item.path),
					recommended: item.recommended,
					popular: item.popular,
					teaser: item.teaser,
					visible: item.visible,
					index: itemIndex,
					inCurrentPage: inCurrentPage
				};
			}));
			clearTimeout(timeout);
		}, 0);
	}

	renderPagination() {

		const pageNumber = this.state.pageNumber;
		const numberOfItems = this.state.items.filter(item => item.visible && item.recommended).length;
		const numberOfPages = Math.ceil(numberOfItems / 3);	//three popular articles are displayed on the page
		let pages = [];
		let arr = [];

		if (numberOfItems <= 3) {	//there's only one page
			return '';
		}
		if (numberOfPages) {
			for (let i = 1; i <= numberOfPages; i++) {
				pages.push(i);
			}
		}
		if (pages.length > 4) {
			for (let i = 2; i < pages.length; i++) {
				if (pageNumber <= 3 && i <= 4) {
					arr.push(i);
				}
				else if (pageNumber >= pages.length - 1 && i >= pages.length - 3) {
					arr.push(i);
				}
				else if (i >= pageNumber - 1 && i <= pageNumber + 1) {
					arr.push(i);
				}
				else {
					if (pageNumber === i - 2 || pageNumber === i + 2) {
						arr.push('...')
					}
					else {
						if (i === 5 && i > pageNumber) {
							arr.push('...')
						}
						if (i === pages.length - 4 && i < pageNumber) {
							arr.push('...')
						}
					}
				}
			}
			pages = [pages[0], ...arr, pages[pages.length - 1]];
		}
		else {
			arr = [pages[1], pages[2]];
		}
		return (
			<PaginationContainer className='pagination'>
				<PaginationIconContainer
					marginRight={px(124)}
					elementDisplay={true}
					onClick={() => pageNumber > 1 ? this.setPage(this.state.pageNumber - 1) : undefined}
					style={this.state.pageNumber === 1 ? {cursor: 'default'} : {cursor: 'pointer'}}
				>
					<Icon
						iconComponent={ChevronLeft}
						primaryColor={this.state.pageNumber === 1 ? IconColor.GRAY : IconColor.BLUE}
						title="ikona"
					/>
				</PaginationIconContainer>
				{numberOfPages > 1 ? pages.map((pageNumber, i) => {
					return (
						<PaginationItem
							key={'item-' + i}
							background={pageNumber === '...' ? 'transparent' : palette.solidWhite}
							onClick={pageNumber === '...' ? () => undefined : () => this.setPage(pageNumber)}
							active={this.state.pageNumber === pageNumber}
						>
							{pageNumber}
						</PaginationItem>
					);
				}) : ''}
				<PaginationIconContainer
					marginLeft={px(124)}
					elementDisplay={true}
					onClick={() => pageNumber < numberOfPages ? this.setPage(this.state.pageNumber + 1) : undefined}
					style={this.state.pageNumber === numberOfPages ? {cursor: 'default'} : {cursor: 'pointer'}}
				>
					<Icon
						iconComponent={ChevronRight}
						primaryColor={this.state.pageNumber === numberOfPages ? IconColor.GRAY : IconColor.BLUE}
						title="ikona"
					/>
				</PaginationIconContainer>
			</PaginationContainer>
		);
	}

	generateArticleBox(item, index) {

		let html = `
			<a class="box-link" href="${site + (environment.type == 'blog' ? item.path.replace('/blog-mauto/blog/', '/') : item.path)}">
				<div class="img-box" style="background-color: ${palette.altoGray}">${item.image.path ?
				`<img src="${site + item.image.path}" alt="${item.image.alt}" title="${item.image.alt}" />` : ''}
				</div>
				<div class="text-box">
					<p class="title">${item.title}</p>
					<p class="teaser">${item.teaser}</p>
					<div class="author-wrapper">
						<div class="author-box">
							${item.avatar ? `<img src=${site + item.avatar} alt="" />` : ''}
							${item.author ? `<p>${item.author}</p>` : ''}
							${item.addInfo ? `<small>${item.addInfo}</small>` : ''}
						</div>
						${item.path ? `<img class="button" src="${site}/.templates/img/icons/svg/red/circle-arrow.svg" alt=${item.title} />` : ''}
					</div>
				</div>
			</a>
		`;
		return html;
	}

	renderRecommendedList() {

		return (<>
			{this.state.items
				.filter(item => item.inCurrentPage)
				.map((item, i) => {

					return (<div
						className='article-box'
						key={'item-' + i}
						data-index={i}
						role='link'
						aria-label={item.title}
						dangerouslySetInnerHTML={{__html: this.generateArticleBox(item, i)}}
					/>);
				})}
			{this.renderPagination()}
		</>);
	}

	getMore(e) {

		displayedLatest = displayedLatest + 3;	//display three at once
		this.updateState('type', 'latest');
		this.updateState('displayed', displayedLatest);
	}

	renderLatestList(index, amount) {

		let start = 0;

		if (index) {
			start = index;
		}
		if (!amount) {
			amount = 4;
		}
		let end = start + amount;
		displayedLatest = 0;

		return (<>
			{this.state.items
				.filter(item => item.visible && item.index >= start && item.index < end)
				.map((item, i) => {

					++displayedLatest;
					return (<div
						className={'article-box' + (item.index === 0 ? ' big' : '')}
						key={'item-' + i}
						data-index={i}
						role='link'
						aria-label={item.title}
						dangerouslySetInnerHTML={{__html: this.generateArticleBox(item, i)}}
					/>);
				})}
			{this.renderGetMore()}
		</>);
	}

	renderGetMore() {

		return (<>
			{displayedLatest < this.state.items.length ? <div id='btn-wrapper' className='get-more'><Button buttonType="primary" color={ButtonColor.BLUE} onClick={(e) => this.getMore(e)}>zobacz więcej artykułów</Button></div> : ''}
		</>);
	}

	render() {
		return (
			<Container>
				<ListContainer>
					<Text as="h2" style={{fontSize: '2rem', fontWeight: 700}} marginBottom={Spacing.SPACE_40}>{this.state.title}</Text>
					{<div id={this.state.type !== 'recommended' ? 'latest' : 'recommended'} className={`articles ${this.state.type}`}>
						{this.state.type === 'recommended' ?
							this.renderRecommendedList() : this.renderLatestList(0, displayedLatest)}
					</div>}
				</ListContainer>
			</Container>
		);
	}
}

export default BlogArticlesList;