/*!
 * mLeasing, page component :: 07/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {environment} from '../../environment.js';
import {DesignSystemThemeProvider} from '@mbank-design/design-system/providers';
import {DesktopApplicationGlobalStyles} from '@mbank-design/design-system/styles';
import {mBank} from '@mbank-design/design-system/themes';
import {Box} from '@mbank-design/design-system/components';
import {Spacing} from '@mbank-design/design-system/enums';
import {Loader} from '@mbank-design/design-system/components';
import {px} from '@mbank-design/design-system/utils';
import palette from '@mbank-design/design-system/palette';
import Cookies from '../components/cookies';
import Navigation from '../components/navigation';
import NavigationMauto from '../components/navigation-mauto';
import Seo from '../components/seo';
import TopCarousel from '../portlets/top-carousel/top-carousel';
import TextBox from '../portlets/text-box/text-box';
import RatingForm from '../portlets/rating-form/rating-form';
import FormBox from '../portlets/form-box/form-box';
import MapBox from '../portlets/map-box/map-box';
import VideoBox from '../portlets/video-box/video-box';
import Faq from '../portlets/faq/faq';
import Article from '../portlets/article/article';
import Tabs from '../portlets/tabs/tabs';
import ImageText from '../portlets/image-text/image-text';
import EdgeMenu from '../portlets/edge-menu/edge-menu';
import TileSet from '../portlets/tile-set/tile-set';
import InfoboxSet from '../portlets/infobox-set/infobox-set';
import ManagemensBox from '../portlets/managemens-box/managemens-box';
import SearchBox from '../portlets/search-box/search-box';
import NewsletterBox from '../portlets/newsletter-box/newsletter-box';
import OfferBox from '../portlets/offer-box/offer-box';
import Calculator from '../portlets/calculator/calculator';
import ContactBox from '../portlets/contact-box/contact-box';
import ContactBoxSet from '../portlets/contact-boxset/contact-boxset';
import DownloadBox from '../portlets/download-box/download-box';
import BoxSet from '../portlets/box-set/box-set';
import ExpContent from '../portlets/exp-content/exp-content';
import Banner from '../portlets/banner/banner';
import ImageBox from '../portlets/image-box/image-box';
import Footer from '../portlets/footer/footer';
import InfoPopup from '../portlets/info-popup/info-popup';
import News from '../portlets/news/news';
import BlogArticle from '../portlets/blog-article/blog-article';
import BlogArticlesList from '../portlets/blog-articles-list/blog-articles-list';
import {mapLinks} from '../utils/map-links.js';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import '../../static/.templates/css/base/classes.css';
import '../../static/.templates/css/base/fonts.css';

const version = 'v: 1.1.0 :: r. 18/12/23 @ pp';
const HeaderContainer = styled(Box)`
	z-index: 3;
	position: fixed;
	width: 100%;
	background: palette.solidWhite;
`;
const MainContainer = styled(Box)`
	padding-top: 100px;

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-top: 93px;
	}
`;
const FooterContainer = styled(Box)`
	position: relative;
`;
const CarouselSection = styled.section`
	background-color: ${palette.wildSandGray};
	padding-top: ${px(Spacing.SPACE_16)};
	padding-bottom: ${px(Spacing.SPACE_16)};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		padding-top: ${px(Spacing.SPACE_0)};
		padding-bottom: ${px(Spacing.SPACE_0)};
	}
`;
const FakeLoaderContainer = styled.div` // necessary to make body loader work
	display: none;
`;

class Page extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.renderPortlet = this.renderPortlet.bind(this);

		let navLinks = props.pageContext.navLinks || [];
		let headerPortlets = props.pageContext.headerPortlets || [];
		let mainPortlets = props.pageContext.mainPortlets || [];
		let footerPortlets = props.pageContext.footerPortlets || [];

		this.state = {
			pageId: props.pageContext.pageId,
			pageType: 'sub',
			page: {
				name: '',
				title: props.pageContext.data.page.title,
				description: props.pageContext.data.page.description,
				url: props.location ? props.location.href : '',
				id: '',
				portlets: props.pageContext.data ? props.pageContext.data : []
			},
			navLinks: navLinks,
			// divide portlets to groups for different containers
			headerPortlets: headerPortlets,
			mainPortlets: mainPortlets,
			footerPortlets: footerPortlets
		};
		/*
		let navLinks = props.navLinks || [];
		let headerPortlets = props.headerPortlets || [];
		let mainPortlets = props.mainPortlets || [];
		let footerPortlets = props.footerPortlets || [];

		this.state = {
			pageId: props.pageId,
			pageType: 'sub',
			page: {
				name: '',
				title: props.data.page.title,
				description: props.data.page.description,
				url: props.location ? props.location.href : '',
				id: '',
				portlets: props.data ? props.data : []
			},
			navLinks: navLinks,
			// divide portlets to groups for different containers
			headerPortlets: headerPortlets,
			mainPortlets: mainPortlets,
			footerPortlets: footerPortlets
		};
		*/
	}

	getVersion() {
		return version;
	}

	static getDerivedStateFromProps(props, state) {

		let navLinks = [];

		// data for server side rendering 
		if (props.navLinks) {
			navLinks = props.navLinks;
		} else if (props.pageContext.navLinks) {
			navLinks = mapLinks(props.pageContext.navLinks);
		}

		return {
			pageId: props.pageId,
			pageType: state.pageType,
			page: {
				name: state.page.name,
				title: state.page.title,
				description: state.page.description,
				url: state.page.url,
				id: state.page.id,
				portlets: state.page.portlets
			},
			navLinks: navLinks && navLinks.length ? navLinks : state.navLinks,
			headerPortlets: state.headerPortlets,
			mainPortlets: state.mainPortlets,
			footerPortlets: state.footerPortlets
		}
	}

	// render different portlets
	renderPortlet(portlet, i) {

		if (portlet.name.match(/top-carousel/)) {
			return (<CarouselSection
				className="top-carousel"
				key={'top-carousel' + i}
			>
				<TopCarousel
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</CarouselSection>
			);
		}

		if (portlet.name.match(/edge-menu/)) {
			return (<Box
				as="div"
				className="edge-menu"
				key={'edge-menu' + i}
			>
				<EdgeMenu
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>
			);
		}

		if (portlet.name.match(/tile-set/)) {
			return (<Box
				as="section"
				className="tile-set"
				key={'tile-set' + i}
				background={portlet.data['Tile-setData']['Tile-set'].Background === 'dark' ? palette.wildSandGray : palette.solidWhite}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
			>
				<TileSet
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>
			);
		}

		if (portlet.name.match(/infobox-set/)) {

			let backgroundColor = palette.solidWhite;
			let portletData = portlet.data['Infobox-setData']['Infobox-set'];

			if (portletData.Background === 'dark' || portletData.Background === 'dark-light') {
				backgroundColor = palette.wildSandGray;
			}

			if (portletData.Background === 'black') {
				backgroundColor = palette.zeusBlackUI;
			}

			return (<Box
				as="section"
				className="infobox-set"
				key={'infobox-set' + i}
				background={backgroundColor}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
			>
				<InfoboxSet
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/managemens-box/)) {

			return (<Box
				as="section"
				className="managemens-box"
				key={'managemens-box' + i}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
			>
				<ManagemensBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/search-box/)) {

			let backgroundColor = palette.wildSandGray;

			if (portlet.data.Standalone === 'true') {
				backgroundColor = palette.solidWhite;
			}

			if (portlet.data.Results === 'true') {
				backgroundColor = palette.wildSandGray;
			}

			return (<Box
				as="section"
				background={backgroundColor}
				className="search-box"
				paddingTop={i > 2 ? px(Spacing.SPACE_48) : px(Spacing.SPACE_0)}
				paddingBottom={i > 2 ? px(Spacing.SPACE_48) : px(Spacing.SPACE_0)}
				key={'search-box' + i}
			>
				<SearchBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/newsletter-box/)) {
			return (<Box
				as="section"
				background={palette.wildSandGray}
				className="newsletter-box"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'newsletter-box' + i}
			>
				<NewsletterBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/offer-box/)) {
			let backgroundColor = palette.wildSandGray;

			if (portlet.data['Offer-boxData']['Offer-box'].Background == 'light') {
				backgroundColor = palette.solidWhite;
			}
			return (<Box
				as="section"
				background={backgroundColor}
				className="offer-box"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'offer-box' + i}
			>
				<OfferBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/calculator/)) {
			return (<Box
				as="section"
				className="calculator"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_0)}
				key={'calculator' + i}
			>
				<Calculator
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/box-set/)) {
			return (<Box
				as="section"
				background={palette.wildSandGray}
				className="box-set"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'box-set' + i}
			>
				<BoxSet
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/exp-content/)) {

			let backgroundColor = palette.solidWhite;

			if (portlet.data['Exp-contentData']['Exp-content'].Background === 'dark') {
				backgroundColor = palette.wildSandGray;
			}

			return (<Box
				as="section"
				className="exp-content"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				background={backgroundColor}
				key={'exp-content' + i}
			>
				<ExpContent
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/banner/)) {

			let uuid = portlet.name.replace('banner-', '');

			return (<Box
				as="section"
				paddingTop={px(Spacing.SPACE_0)}
				paddingBottom={px(Spacing.SPACE_0)}
				className="banner"
				key={'banner' + i}
			>
				<Banner
					data={portlet.data}
					uuid={uuid}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
					pageTitle={this.state.page.title}
				/>
			</Box>);
		}

		if (portlet.name.match(/contact-box-/)) {
			return (<Box
				as="section"
				background={palette.wildSandGray}
				paddingTop={px(Spacing.SPACE_32)}
				paddingBottom={px(Spacing.SPACE_32)}
				className="contact-box"
				key={'contact-box' + i}
			>
				<ContactBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/contact-boxset/)) {

			let paddingBottom = px(Spacing.SPACE_48);

			if (portlet.data.WithoutBottomPadding !== 'false') {
				paddingBottom = px(Spacing.SPACE_0);
			}

			return (<Box
				as="section"
				key={'contact-boxset' + i}
				background={palette.wildSandGray}
				className="contact-boxset"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={paddingBottom}
			>
				<ContactBoxSet
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/download-box/)) {

			let backgroundColor = palette.solidWhite;

			if (portlet.data['DownloadboxData']['Downloadbox'].Background === 'dark') {
				backgroundColor = palette.wildSandGray;
			}

			return (<Box
				as="section"
				background={backgroundColor}
				paddingTop={px(Spacing.SPACE_32)}
				paddingBottom={px(Spacing.SPACE_32)}
				className="download-box"
				key={'download-box' + i}
			>
				<DownloadBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/text-box/)) {

			let backgroundColor = palette.solidWhite;

			if (portlet.data['Text-boxData']['Text-box'].Background === 'dark') {
				backgroundColor = palette.wildSandGray;
			}

			return (<Box
				as="section"
				className="text-box"
				background={backgroundColor}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'text-box' + i}
			>
				<TextBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}
		if (portlet.name.match(/rating-form/)) {

			let backgroundColor = palette.solidWhite;

			if (portlet.data['RatingData']['Rating'].Background === 'dark') {
				backgroundColor = palette.wildSandGray;
			}

			return (<Box
				as="section"
				className="rating-form"
				background={backgroundColor}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'rating-form' + i}
			>
				<RatingForm
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}
		if (portlet.name.match(/form-box/)) {

			let backgroundColor = palette.solidWhite;
			let paddingBottom = px(Spacing.SPACE_0);

			if (portlet.data.Form && portlet.data.Form === '2') {
				backgroundColor = palette.wildSandGray;
				paddingBottom = px(Spacing.SPACE_48);
			}

			return (<Box
				as="section"
				className="form-box"
				background={backgroundColor}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={paddingBottom}
				key={'form-box' + i}
			>
				<FormBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/map-box/)) {
			return (<Box
				as="section"
				className="map-box"
				background={palette.solidWhite}
				paddingTop={px(Spacing.SPACE_0)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'map-box' + i}
			>
				<MapBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/video-box/)) {

			return (<Box
				as="section"
				className="video-box"
				background={palette.wildSandGray}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'video-box' + i}
			>
				<VideoBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/faq/)) {

			return (<Box
				as="section"
				className="faq"
				background={palette.wildSandGray}
				paddingTop={px(Spacing.SPACE_0)}
				paddingBottom={px(Spacing.SPACE_0)}
				key={'faq' + i}
			>
				<Faq
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/blog-articles-list/)) {
			return (<Box
				as="section"
				className="blog-articles-list"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				background={palette.wildSandGray}
				key={'blog-articles-list' + i}
			>
				<BlogArticlesList
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/blog-article/)) {

			let backgroundColor = palette.solidWhite;

			return (<Box
				as="section"
				className="blog-article"
				background={backgroundColor}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'blog-article' + i}
			>
				<BlogArticle
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/article/)) {

			let backgroundColor = palette.solidWhite;

			if (portlet.data['ArticleData']['Article'].Background === 'dark' || portlet.data['ArticleData']['Article'].Background === 'dark-light') {
				backgroundColor = palette.wildSandGray;
			}

			return (<Box
				as="section"
				className="article"
				background={backgroundColor}
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'article' + i}
			>
				<Article
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/tabs/)) {
			return (<Box
				as="section"
				className="tabs"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				key={'tabs' + i}
			>
				<Tabs
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/image-text/)) {

			let backgroundColor = palette.solidWhite;

			if (portlet.data['Image-textData']['Image-text'].Background === 'dark') {
				backgroundColor = palette.wildSandGray;
			}

			if (portlet.data['Image-textData']['Image-text'].Background === 'black') {
				backgroundColor = palette.zeusBlackUI;
			}

			return (<Box
				as="section"
				className="image-text"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				background={backgroundColor}
				key={'image-text' + i}
			>
				<ImageText
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/image-box/)) {
			return (<Box
				as="section"
				className="image-box"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				background={palette.wildSandGray}
				key={'image-box' + i}
			>
				<ImageBox
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}

		if (portlet.name.match(/footer/)) {
			return (<Footer
				data={portlet.data}
				key={portlet.name + i}
				prefix={environment.onlineCatalog}
				pageType={this.state.pageType}
			/>);
		}

		if (portlet.name.match(/info-popup/)) {
			return (<InfoPopup
				data={portlet.data}
				key={portlet.name + i}
				prefix={environment.onlineCatalog}
				pageType={this.state.pageType}
			/>);
		}

		if (portlet.name.match(/news/)) {
			return (<Box
				as="section"
				className="news"
				paddingTop={px(Spacing.SPACE_48)}
				paddingBottom={px(Spacing.SPACE_48)}
				background={palette.wildSandGray}
				key={'news' + i}
			>
				<News
					data={portlet.data}
					key={portlet.name + i}
					prefix={environment.onlineCatalog}
					pageType={this.state.pageType}
				/>
			</Box>);
		}
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('page');
		}

		// reffer window only after component mounted, to prevent build errors
		let path = window.location.pathname.split('/').filter(e => e).join('/');
		let apiPath = environment.onlineApiPath + path + (path !== '' ? '/' : '') + environment.onlineApiFileName;

		fetch(apiPath)
			.then(res => res.json())
			.then(json => {
				this.createPortlets(json);
			}).then(json => {

				if (window && window.document) {

					const loader = document.querySelector('#loader');
					const body = document.querySelector('body');

					if (loader) {
						loader.classList.remove('hidden');
					}

					if (body) {
						body.classList.remove('sub');
						body.classList.remove('hp');
						body.classList.add(this.state.pageType);
					}

					setTimeout(() => {

						if (body) {
							body.classList.remove('hidden');
						}

						if (loader) {
							loader.classList.add('hidden');
						}

					}, 1000);
				}

			});

	}

	createPortlets(json) {

		const obj = json;
		const page = obj && obj.page ? obj.page : this.state.page;
		const pageParts = obj && obj.parts ? obj.parts.filter(part => part !== 'page') : [];
		const pageType = page.home ? 'hp' : 'sub';
		let headerPortlets = [];
		let mainPortlets = [];
		let footerPortlets = [];

		// group portlets by containers
		for (let resource in json) {

			if (resource !== 'page' && resource !== 'parts') {

				const obj = {
					name: resource,
					data: json[resource]
				};

				if (resource.match(/footer/)) {
					footerPortlets.push(obj);
				} else {
					mainPortlets.push(obj);
				}
			}

		}

		// sort portlets to determine order in container
		let headerPortletsSorted = [];
		let mainPortletsSorted = [];
		let footerPortletsSorted = [];

		pageParts.forEach(part => {

			const mainPortlet = mainPortlets.filter(portlet => portlet.name === part);
			const headerPortlet = headerPortlets.filter(portlet => portlet.name === part);
			const footerPortlet = footerPortlets.filter(portlet => portlet.name === part);

			if (headerPortlet.length) {
				headerPortletsSorted.push(headerPortlet[0]);
			}
			if (mainPortlet.length) {
				mainPortletsSorted.push(mainPortlet[0]);
			}
			if (footerPortlet.length) {
				footerPortletsSorted.push(footerPortlet[0]);
			}
		});
		this.setState({
			page: {
				title: page.title,
				description: page.description,
				url: this.state.page.url
			},
			pageType: pageType,
			headerPortlets: headerPortletsSorted,
			mainPortlets: mainPortletsSorted,
			footerPortlets: footerPortletsSorted
		});
	}

	render() {
		return (
			<DesignSystemThemeProvider theme={mBank}>
				<DesktopApplicationGlobalStyles />
				<Seo
					title={this.state.page.title}
					description={this.state.page.description}
					url={this.state.page.url}
				/>
				<HeaderContainer
					as="header"
					id="header"
					className="header"
					style={{
						'zIndex': 2,
						'position':
							'fixed',
						'width': '100%',
						'background': palette.solidWhite
					}}
				>
					<Box as="div" id="header-content" className="wrapper">
						{environment.type == 'blog' &&
							<NavigationMauto
								prefix={environment.onlineCatalog}
								navLinks={this.state.navLinks}
								pageType={this.state.pageType}
							/>
						}
						{environment.type !== 'blog' &&
							<Navigation
								prefix={environment.onlineCatalog}
								navLinks={this.state.navLinks}
								pageType={this.state.pageType}
							/>
						}
					</Box>
				</HeaderContainer>
				<MainContainer
					as="main"
					id="main"
					className="main"
					style={{
						'paddingTop': '100px'
					}}
				>
					<Box as="div" className="wrapper">
						{this.state.mainPortlets.map((portlet, i) => this.renderPortlet(portlet, i))}
					</Box>
				</MainContainer>
				<FooterContainer
					as="footer"
					id="footer"
					className="footer"
				>
					<Box as="div" className="wrapper">
						{this.state.footerPortlets.map((portlet, i) => this.renderPortlet(portlet, i))}
					</Box>
					<Cookies />
				</FooterContainer>
				<FakeLoaderContainer>
					<Loader />
				</FakeLoaderContainer>
			</DesignSystemThemeProvider>
		);
	}
}

export default Page;
