/*!
 * mLeasing, actions navigation component :: 18/01/2021
 * Copyright (C) QUERCUS, https://qrqs.eu
 */

import React from 'react';
import styled from 'styled-components';
import {Icon} from '@mbank-design/design-system/components';
import {MagnifyingGlass} from '@mbank-design/design-system/icons';
import {PhoneHandle} from '@mbank-design/design-system/icons';
import {Button} from '@mbank-design/design-system/components';
import {Box} from '@mbank-design/design-system/components';
import {IconColor} from '@mbank-design/design-system/enums';
import breakpoints from '@mbank-design/design-system/styles/breakpoints';
import palette from '@mbank-design/design-system/palette';
import {Spacing} from '@mbank-design/design-system/enums';
import {px} from '@mbank-design/design-system/utils';
import {generateLink} from '../utils/generate-link.js';
import '../../static/.templates/css/base/nav-main.css';

const version = 'v: 1.0.1 :: r. 13/12/24 @ vlk';
const ActionsList = styled.ul`
	display: none;
	padding: 0;

	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1285px) {
		padding-left: ${px(Spacing.SPACE_20)};
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1230px) {
		padding-left: ${px(Spacing.SPACE_12)};
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1070px) {
		padding-left: ${px(Spacing.SPACE_8)};
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		flex-direction: row;
		padding-right: ${px(Spacing.SPACE_16)};
		padding-left: ${px(Spacing.SPACE_16)};
		margin-top: ${Spacing.SPACE_0};
		margin-bottom: ${Spacing.SPACE_0};
		display: flex;
        position: absolute;
        right: 20px;
	}
`;
const ActionsItem = styled.li`
	display: flex;
	align-items: center;
	margin-left: ${px(Spacing.SPACE_8)};
	margin-right: ${px(Spacing.SPACE_8)};

	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
		margin-right: ${px(Spacing.SPACE_4)};
		margin-left: ${px(Spacing.SPACE_4)};
	}
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		margin-right: ${px(Spacing.SPACE_0)};
		margin-left:  ${px(Spacing.SPACE_0)};
		padding-top: ${px(Spacing.SPACE_12)};
		padding-bottom: ${px(Spacing.SPACE_12)};
	}
`;
const LastActionsItem = styled(ActionsItem)`
	margin-right: ${Spacing.SPACE_0};

	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
		border: none;
		justify-content: center;
		padding-top: ${px(Spacing.SPACE_24)};
		padding-bottom: 0;
	}
`;
const DiffOrderActionsItem = styled(LastActionsItem)`
	margin-right: ${px(Spacing.SPACE_8)};
	@media screen and (max-width: ${px(breakpoints.mobileMax)}) {
        order: 4;
		margin-right: 0;
    }
`;
const ModButton = styled(Button)`
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1285px) {
		font-size: 0.8rem;
	}
	@media screen and (min-width: ${px(breakpoints.mobileMax)}) and (max-width: 1130px) {
		font-size: 0.7rem;
	}
`;
const linkStyles = `
	display: flex;
	align-items: center;
	flex-wrap: nowrap
`;
const loginLink = 'https://online.mleasing.pl/';
const helpLink = 'https://mauto.pl/lista-pojazdow?isCompany=true&financialMethod=all&selectedSortingValue=0';
const contactLink = '/kontakt';
const formLink = '/zapytanie-o-finansowanie/';

class Actions extends React.Component {

	constructor(props) {
		super(props);
		this.getVersion = this.getVersion.bind(this);
		this.offlineClickHandler = this.offlineClickHandler.bind(this);
		this.state = {
			offline: props.offline
		};
	}

	getVersion() {
		return version;
	}

	componentDidMount() {
		if (window.origin.includes('localhost') || window.origin.includes('test-mleasing11')) {
			console.log('actions menu');
		}
	}

	offlineClickHandler(e) {

		if (this.state.offline) { // offline only
			window.location.href = e.target.parentElement.href;
		}

	}

	render() {
		return (
			<ActionsList className="actions">
				<ActionsItem style={{marginRight: '20px'}}>
					{generateLink(helpLink, '', linkStyles, (
						<>
							<Icon iconComponent={MagnifyingGlass} primaryColor={IconColor.BLUE} title="wyszukaj" />
						</>
					), this.state.offline)}
				</ActionsItem>
				<ActionsItem style={{marginRight: '20px'}}>
					{generateLink(contactLink, '', linkStyles, (
						<>
							<Icon iconComponent={PhoneHandle} primaryColor={IconColor.BLUE} title="kontakt" />
						</>
					), this.state.offline)}
				</ActionsItem>
			</ActionsList>
		);
	}
}

export default Actions;